import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import {
  ArrowBack,
  Delete as DeleteIcon,
  Edit as EditIcon,
  GroupAdd,
  PersonAdd,
} from "@material-ui/icons";
import Icon from "@mdi/react";
import { Error } from "@material-ui/icons";
import { mdiNeedle } from "@mdi/js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ToastMessage from "../../../../components/Universal/toast_message";
import {
  clinicIsEditable,
  humanTime,
  humanDate,
  isBeforeToday,
} from "../../../../lib/common_utils";
import {
  clinicTypeElement,
  enumClinicTypeId,
  enumClinicTypeIdLabels,
  enumRecurrenceId,
} from "../../../../lib/enums";
import DataClient from "../../../../lib/services/api/DataClient";
import {
  isClinicAdminOrHigher,
  isVaccinatorOrHigher,
} from "../../../../lib/services/TrustSwitcher";

import ConfirmDialog from "../../ConfirmDialog";
import { CreateDialog } from "../Staff/Create.jsx";
import { VaccinationDetails } from "../VaccinationDetails";
import AddPatient from "./AddPatient";
import { BookingDetails } from "./BookingDetails";
import "./Clinic-styles.scss";
import { vaccineBatchInformalName } from "./VaccineBatches";
import { Pathogens } from "../../../../lib/pathogens";
import Help from "../../../../components/Help/Help";
import { Alert, AlertTitle } from "@material-ui/lab";
import useInterval from "../../../../lib/hooks/useInterval";

const clinicIsRecurring = ({ recurrenceId }) =>
  enumRecurrenceId[recurrenceId] !== "None";
export const clinicAllowsBookings = (clinicTypeId) =>
  [
    enumClinicTypeId["bookingsOnly"],
    enumClinicTypeId["bookingsWithDropIns"],
  ].includes(clinicTypeId);
export const ClinicDetails = (clinic) => {
  const {
    availableSlot,
    bookedSlot,
    breakTimes,
    clinicLocationName,
    clinicTypeId,
    date,
    duration,
    hospitalSiteName,
    numberOfSpaces,
    pathogens,
    requireBookingAllPathogens,
    totalDropInReservations,
    totalSlot,
    totalDropInSlot,
    availableDropInSlot,
    bookedDropInSlot,
  } = clinic || {};
  return (
    <Grid
      container
      direction="row"
      // spacing={2}
      justifyContent="flex-start"
      className="vaccination_clinic_details-info -details"
    >
      <Grid item xs={6} md={2} className="vaccination_clinic_details-item">
        <h3>Clinic Type</h3>
        {clinicTypeElement({ pathogens, requireBookingAllPathogens })}
      </Grid>
      <Grid item xs={6} md={2} className="vaccination_clinic_details-item">
        <h3>Site</h3>
        <p>{hospitalSiteName || `-`}</p>
      </Grid>
      <Grid item xs={6} md={2} className="vaccination_clinic_details-item">
        <h3>Clinic Location</h3>
        <p>{clinicLocationName || `-`}</p>
      </Grid>
      {/* <Grid item xs={6} md={2} className="vaccination_clinic_details-item">
          <h3
          >
            Vaccinator Name
          </h3>
          <p>
            {clinic_vaccinators && clinic.clinic_vaccinators.length > 0
              ? flattenObjectToArray(clinic.clinic_vaccinators, "name").join(
                  ", "
                )
              : "Unassigned"}
          </p>
        </Grid> */}
      <Grid item xs={6} md={2} className="vaccination_clinic_details-item">
        <h3>Booking Model</h3>
        <p>{enumClinicTypeIdLabels[enumClinicTypeId[clinicTypeId]] || "N/A"}</p>
        {clinicIsRecurring(clinic) ? "Recurring" : "One-off"}
      </Grid>
      <Grid item xs={6} md={2} className="vaccination_clinic_details-item">
        <h3>Number of Vaccinators</h3>
        <p>{numberOfSpaces || "N/A"}</p>
      </Grid>
      <Grid item xs={6} md={2} className="vaccination_clinic_details-item">
        <h3>Date</h3>
        <p>
          {date ? moment(date, "YYYY-MM-DD").format("DD/MM/YYYY") : "N/A"}
          {clinicIsRecurring(clinic) ? <span> onwards</span> : ""}
        </p>
      </Grid>
      <Grid item xs={6} md={2} className="vaccination_clinic_details-item">
        <h3>Duration</h3>
        <p>{duration || "N/A"}</p>
      </Grid>
      <Grid item xs={6} md={2} className="vaccination_clinic_details-item">
        <h3>Break Times</h3>
        {breakTimes?.length ? (
          <ul>
            {breakTimes.map((b, i) => (
              <li key={`breakTimes-${i}`}>
                {`${moment(b.start, "HH:mm:ss").format("HH:mm")} -
                        ${moment(b.end, "HH:mm:ss").format("HH:mm")}`}
              </li>
            ))}
          </ul>
        ) : (
          <p>N/A</p>
        )}
      </Grid>
      <Grid item xs={6} md={2} className="vaccination_clinic_details-item">
        <h3>Total Slots</h3>
        <p>
          {/* Similar N/A blocking in both View an PlannerList */}
          {clinicAllowsBookings(clinicTypeId)
            ? `${totalSlot} (${totalDropInSlot})`
            : "N/A"}
        </p>
      </Grid>
      <Grid item xs={6} md={2} className="vaccination_clinic_details-item">
        <h3>Available Slots</h3>
        <p>
          {clinicAllowsBookings(clinicTypeId)
            ? `${availableSlot} (${availableDropInSlot})`
            : "N/A"}
        </p>
      </Grid>
      <Grid item xs={6} md={2} className="vaccination_clinic_details-item">
        <h3>Booked Slots</h3>
        <p>
          {clinicAllowsBookings(clinicTypeId)
            ? `${bookedSlot || 0} (${bookedDropInSlot})`
            : "N/A"}
        </p>
      </Grid>
      <Grid item xs={6} md={2} className="vaccination_clinic_details-item">
        <h3>Drop-in Bookings</h3>
        <p>{totalDropInReservations || 0}</p>
      </Grid>
    </Grid>
  );
};
const BatchInfo = (vaccinationBatchInfo, i) => {
  if (!vaccinationBatchInfo) return null;
  return (
    <li key={`batch-${i}`}>{vaccineBatchInformalName(vaccinationBatchInfo)}</li>
  );
};
const BatchInfos = ({ clinic, pathogen }) => {
  const { vaccinationBatchInfos } = clinic;
  const batches = vaccinationBatchInfos?.filter(
    (b) => b.pathogenId === pathogen.id
  );
  return (
    <Grid
      container
      direction="column"
      className="vaccination_clinic_details-info -vaccine"
    >
      {/* Remaining doses is removed temporarily, as these will likely be moved from batch level to clinic */}
      <h3>Available Batches</h3>
      <ul className="list-simple">{batches?.map(BatchInfo)}</ul>
      <h3>Remaining Doses Required</h3>
      <p>
        {clinic[
          pathogen.name.substr(0, 1).toLowerCase() +
            pathogen.name.substr(1) +
            "RemainingAppointmentsToday"
        ] || "-"}
      </p>
    </Grid>
  );
};
const constructBookingObject = (booking) => {
  const { timeFrom, timeTo } = booking;
  booking.name = `${booking.firstName || ""} ${booking.lastName || ""}`.trim();
  booking.timeSlot =
    timeFrom && timeTo
      ? `${humanTime(timeFrom)} - ${humanTime(timeTo)}`
      : "Drop In";
  return booking;
};
export default (props) => {
  const clinicId = props?.match?.params?.clinicId;
  const { history } = props;
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [shouldInit, setShouldInit] = useState(true);
  const [activeModal, setActiveModal] = useState(props?.activeModal);
  const [bookings, setBookings] = useState([]);
  const [userAction, setUserAction] = useState(null);
  const [toast, setToast] = useState(null);
  const [clinic, setClinic] = useState({});
  const [staffJustCreated, setStaffJustCreated] = useState(null);
  const [suspended, setSuspended] = useState(false);
  // States for Vaccination Details modal, with set functions to operate within BookingDetails component
  const [trustWorkerFlags_VD, setTrustWorkerFlags_VD] = useState(props?.match?.params?.trustWorkerId);
  const [trustWorkerId_VD, setTrustWorkerId_VD] = useState(
    props?.match?.params?.trustWorkerId,
  );
  const [trustWorkerName_VD, setTrustWorkerName_VD] = useState(
    props?.match?.params?.trustWorkerName,
  );
  const [trustWorkerDob_VD, setTrustWorkerDob_VD] = useState(null);
  const [pathogenId_VD, setPathogenId_VD] = useState(
    props?.match?.params?.pathogenId,
  );
  const [pathogenName_VD, setPathogenName_VD] = useState(
    props?.match?.params?.pathogenName,
  );
  const [unplannedVaccation_VD, setUnplannedVaccation_VD] = useState(false);

  const [onlyRequiresAdminFilter, _setOnlyRequiresAdminFilter] = useState(null);
  const setOnlyRequiresAdmin = (val) => {
    if (val === true) {
      _setOnlyRequiresAdminFilter(true);
    } else {
      _setOnlyRequiresAdminFilter(null);
    }
  };

  // Can be a number (count of users that require admin) or Null (not showing)
  const [showRequiresAdminModal, setShowRequiresAdminModal] = useState(null);

  const fetchClinic = () => {
    Promise.all([
      DataClient.getData(`/Clinic/${clinicId}`),
      DataClient.getData(
        `/Reservation/ShowAllBookings?clinicId=${clinicId}&pageSize=-1${
          onlyRequiresAdminFilter ? "&requiringAdmin=true" : ""
        }`,
      ),
    ])
      .then((res) => {
        const clinicRes = res[0];
        const bookingsRes = res[1];
        if (clinicRes.success && clinicRes.results) {
          setClinic(clinicRes.results || {});
          setSuspended(clinicRes?.results?.isBookingSuspended);
        } else {
          setClinic({});
          setToast({
            type: "error",
            message: `Something went wrong while fetching clinic data`,
          });
        }
        if (bookingsRes?.results?.length) {
          setBookings(bookingsRes.results.map(constructBookingObject));
        } else {
          console.log("set bookings empty");
          setBookings([]);
        }
        setLoading(false);
        setShouldInit(false);
      })
      .catch((res) => {
        setToast({ type: "error", message: `Something went wrong. ${res}` });
        setLoading(false);
        setShouldInit(false);
        setFailed(true);
      });
  };
  useEffect(() => {
    fetchClinic();
  }, [clinicId, shouldInit]);
  const handleClose = () => {
    setUserAction(null);
  };
  const handleNewBookingAdd = (newData) => {
    const allBookingDetails = [...bookings];
    allBookingDetails.push(constructBookingObject(newData));
    if (allBookingDetails?.length) {
      setBookings(allBookingDetails);
    }
    // setShouldInit(true); // reload the page essentially
    fetchClinic();
    handleClose();
  };

  const setIsPresent = (reservationId, isPresent) => {
    DataClient.update(
      `/Reservation/SetPresence/${reservationId}?isPresent=${isPresent}`,
    ).then(() => {
      fetchClinic();
    });
  };

  const postSetManualAdminCheck = () => {
    setLoading(true);
    DataClient.call({
      url: `/Clinic/${clinic.id}/SetManualAdminCheck`,
      method: "PUT",
    })
      .client.then(async (resClinic) => {
        fetchClinic();
      })
      .catch((e) => {
        setToast({
          type: "error",
          message: e.message,
        });
        console.error(e);
      })
      .finally(() => {
        setShowRequiresAdminModal(null);
        setLoading(false);
      });
  };

  const manualAdminCheck = (clinic) => {
    DataClient.update(`/Clinic/${clinicId}/SetManualAdminCheck`).then((res) => {
      fetchClinic();
    });
  };

  const checkForNeedsAdminUsers = (clinic) => {
    DataClient.getData(
      `/Reservation/GetAllBookings?clinicId=${clinicId}&potentiallyRequiringAdmin=true&pageSize=1`,
    ).then((res) => {
      const reqAdminCount = res.results.totalCount; // to be fetched
      setShowRequiresAdminModal(reqAdminCount);
    });
  };

  const requiresAdminCount = bookings.filter((b) => b.requiresAdmin).length;

  const { pathogens, manuallyAddStaff } = clinic;

  const dateIsToday = (dateToCheck) =>
    new Date(dateToCheck).toDateString() === new Date().toDateString();

  /*** POLLING THE TABLE DATA dd#4442 ***/
  useInterval(fetchClinic, 10000);
  return (
    <>
      <main className="wrapper-main">
        <h1>Clinic Details</h1>
        {failed ? (
          <Alert severity="error">
            <AlertTitle>Not Found</AlertTitle>
            <p>There was a problem finding this clinic.</p>
          </Alert>
        ) : (
          <Grid item>
            <Grid container spacing={4}>
              <Grid item md={8}>
                <Grid container item justifyContent="space-between">
                  <Button
                    onClick={() => {
                      window.close();
                      // fallback if failing to close tab
                      setTimeout(() => {
                        history.replace("/clinics");
                      }, 1000);
                    }}
                    color={"primary"}
                    startIcon={<ArrowBack />}
                  >
                    Back to Planner
                  </Button>
                  {clinicIsEditable(clinic.date) && isClinicAdminOrHigher() && (
                    <Box mb={2}>
                      <Box ml={2} display="inline">
                        <Button
                          component={Link}
                          to={`/clinics/${clinicId}/delete`}
                          // onClick={() => setUserAction("cancel")}
                          startIcon={<DeleteIcon />}
                        >
                          Cancel Clinic
                        </Button>
                      </Box>
                      <Box ml={2} display="inline">
                        <FormControlLabel
                          className={`filter-checkbox ${
                            suspended ? "checkbox-suspended" : ""
                          }`}
                          control={
                            <Checkbox
                              onChange={(e) => {
                                e.preventDefault();
                                setActiveModal("pausing");
                              }}
                              checked={suspended}
                            />
                          }
                          label={
                            suspended
                              ? "Bookings Suspended"
                              : "Suspend Bookings"
                          }
                        />
                      </Box>
                      <Box ml={2} display="inline">
                        <Button
                          component={Link}
                          to={
                            clinicIsRecurring(clinic)
                              ? `/clinics/${clinicId}/split`
                              : `/clinics/${clinicId}/edit`
                          }
                          color="primary"
                          variant="contained"
                          startIcon={<EditIcon />}
                        >
                          Edit Clinic
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Grid>
                <Grid
                  item
                  style={{
                    height: "calc(100% - 52px)",
                    backgroundColor: "white",
                    borderRadius: "0.3rem",
                  }}
                >
                  <ClinicDetails {...clinic} />
                </Grid>
              </Grid>
              {pathogens?.map((pathogen) => (
                <Grid
                  item
                  container
                  md={Math.floor(4 / pathogens?.length) || 3}
                  style={{ flexFlow: "column" }}
                  key={`pathogenGrid=${pathogen.id}`}
                >
                  {clinicIsEditable(clinic.date) && isVaccinatorOrHigher() && (
                    <div className="help-tooltip-wrapper">
                      <Button
                        component={Link}
                        to={`/clinics/${clinicId}/vaccine-batches/${pathogen.id}`}
                        color="primary"
                        variant="contained"
                        data-theme-id={pathogen?.id}
                        style={{ width: "100%" }}
                        startIcon={
                          // Syringe
                          <Icon path={mdiNeedle} size={1} color="white" />
                        }
                      >
                        {pathogen?.name} Vaccine Batches
                      </Button>
                      {pathogen.id === Pathogens["Flu"] ? (
                        <Help helpCopyKey="2.3" />
                      ) : pathogen.id === Pathogens["Covid"] ? (
                        <Help helpCopyKey="2.4" />
                      ) : null}
                    </div>
                  )}
                  <Grid
                    item
                    container
                    style={{ minHeight: "13em", marginTop: "1em" }}
                  >
                    <BatchInfos {...{ clinic, pathogen }} />
                  </Grid>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Box my={6}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <h1>Bookings</h1>
                    <Box mb={1}>
                      <Grid container item spacing={2} alignItems="top">
                        <Grid item>
                          {(clinic.manualAdminCheck ||
                            isBeforeToday(clinic.date)) &&
                          requiresAdminCount === 0 ? (
                            <div className="help-tooltip-wrapper">
                              <Button
                                className="hacked-button"
                                disableRipple
                                color="success"
                                variant="contained"
                                onClick={() => null}
                              >
                                Clinic Closed
                              </Button>
                              <Help helpCopyKey="2.6" />
                            </div>
                          ) : requiresAdminCount > 0 ? (
                            <Button
                              style={{ height: "100%" }}
                              severity="error"
                              variant="outlined"
                              className="alertBoxCountContainer alertBoxCountButton"
                              onClick={() => {
                                setOnlyRequiresAdmin(!onlyRequiresAdminFilter);
                                setLoading(true);
                                fetchClinic();
                              }}
                            >
                              {!onlyRequiresAdminFilter && (
                                <span className="errorIcon">
                                  <Error />
                                </span>
                              )}
                              {onlyRequiresAdminFilter
                                ? "Show All Staff"
                                : `Show ${requiresAdminCount} Staff ${
                                    requiresAdminCount === 1 ? "Member" : ""
                                  } Requiring Admin`}
                            </Button>
                          ) : (
                            dateIsToday(clinic.date) && (
                              <div className="help-tooltip-wrapper">
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() => {
                                    checkForNeedsAdminUsers(clinic);
                                  }}
                                >
                                  Close Clinic
                                </Button>
                                <Help helpCopyKey="2.5" />
                              </div>
                            )
                          )}
                        </Grid>

                        
                      {/* Client wanted to add people to past clinics,
                    Then they wanted to hide it for past clinics,
                    THEN they wanted it back again - for admins at least
                    but not on the /book-appointment select clinic widget
                    https://www.youtube.com/watch?v=Nfrk2UdEOcQ&t=74s */}
                      {/* {isTodayOrFuture && ( */}
                      {clinicIsEditable(clinic.date) && !suspended && (
                          <>
                            <Grid item>
                              <div className="help-tooltip-wrapper">
                                <Button
                                    onClick={() => setUserAction("add")}
                                    color="secondary"
                                    variant="contained"
                                    startIcon={<GroupAdd />}
                                >
                                  Add Drop In
                                </Button>
                                <Help helpCopyKey="2.2" />
                              </div>
                            </Grid>
                            {/* <Button
                        onClick={() => setShowCreateDialog(true)}
                        color="secondary"
                        variant="contained"
                        startIcon={<PersonAdd />}
                      >
                        Add Non ESR Staff
                      </Button> */}
                            {manuallyAddStaff && (
                                <Grid item style={{ paddingRight: 0 }}>
                                  <div className="help-tooltip-wrapper">
                                    <Button
                                        // component={Link}
                                        // to="/staff/create"
                                        onClick={() =>
                                            setActiveModal("manuallyAddStaff")
                                        }
                                        color="secondary"
                                        variant="contained"
                                        startIcon={<PersonAdd />}
                                    >
                                      Manually Add Staff
                                    </Button>
                                    <Help helpCopyKey="2.1" />
                                  </div>
                                </Grid>
                            )}

                          </>
                          )}
                        </Grid>
                    </Box>
                  </Grid>
                  <BookingDetails
                    {...{
                      history,
                      clinicId,
                      clinic,
                      bookings,
                      clinicPathogens: pathogens,
                        setActiveModal,
                      setTrustWorkerFlags: setTrustWorkerFlags_VD,
                      setTrustWorkerId_VD,
                      setTrustWorkerName_VD,
                      setTrustWorkerDob_VD,
                      setPathogenId_VD,
                      setPathogenName_VD,
                      setUnplannedVaccation_VD,
                      setIsPresent,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            {/* <div style={{ minHeight: "100px" }}></div> */}
          </Grid>
        )}
      </main>
      {/* {userAction === "edit" && (
        <UpdateClinic
          handleClose={handleClose}
          handleNewData={handleNewData}
          data={clinic}
          setAction={setAction}
        />
      )} */}
      {/* {userAction === "cancel" && (
        <CancelClinic
          clinicId={clinic["id"]}
          setAction={setAction}
          handleClose={handleClose}
          history={props.history}
        />
      )} */}
      {userAction === "add" && (
        <AddPatient
          clinicId={clinic.id}
          clinicPathogenIds={clinic?.pathogens?.map((p) => p.id)}
          setAction={setToast}
          handleNewBookingAdd={handleNewBookingAdd}
          handleClose={handleClose}
        />
      )}
      {/* {userAction === "update_patient" && (
        <UpdateVaccinationDetails
          vaccinators={clinic.clinic_vaccinators}
          setAction={setAction}
          data={currentSelection}
          handleClose={handleClose}
          setUpdatedData={setUpdatedData}
        />
      )} */}
      {activeModal === "vaccinationDetails" && (
        <VaccinationDetails
          {...{
            clinicId,
            trustWorkerName: trustWorkerName_VD,
            trustWorkerDob: trustWorkerDob_VD,
            trustWorkerId: trustWorkerId_VD,
            pathogenId: pathogenId_VD,
            pathogenName: pathogenName_VD,
            unplannedVaccination: unplannedVaccation_VD,
            closeCallback: (updated) => {
              setActiveModal(null);
              fetchClinic();
            },
            trustWorkerFlags: trustWorkerFlags_VD,
          }}
        />
      )}
      {activeModal === "manuallyAddStaff" && (
        <CreateDialog
          {...{
            open: true,
            onClose: () => {
              setActiveModal(null);
            },
            onSuccess: (response) => {
              if (!response?.results) {
                setToast({
                  type: "success",
                  message: `**The staff member’s record (${
                    response.firstName
                  } ${response.lastName}${
                    response.dateOfBirth
                      ? ", " + humanDate(response.dateOfBirth)
                      : " "
                  }) has been successfully restored.**  \n  \nYou can now add them to the clinic using the 'Add Drop In' button`,
                });
                setActiveModal(null);
                return;
              }
              setStaffJustCreated(response.results || null);
              setActiveModal("staffCreated");
            },
            onError: (messages) => {
              if (messages.status === 409) {
                messages = messages.errors;
              }
              setToast({
                type: "error",
                message: Array.isArray(messages) ? messages[0] : messages,
              });
            },
          }}
        />
      )}
      {activeModal === "staffCreated" && (
        <ConfirmDialog
          {...{
            open: true,
            title: "New Staff Record Created",
            // #dd-387 Removing the automatic staff adding functionality by request, but leaving for ref
            // onConfirm: () => {
            // setLoading(true);
            // DataClient.call({
            //   url: `Reservation/AddPatient`,
            //   method: "POST",
            //   data: {
            //     trustWorkerId: staffJustCreated.id,
            //     clinicId: clinic.id,
            //     pathogens: staffJustCreated.pathogens,
            //   },
            // })
            //   .client.then(async (res) => {
            //     // Creating new staff then adding to clinic always ought to be successful, right?
            //     if (!res.reservationId) {
            //       setAction({
            //         type: "error",
            //         message: `Something went wrong while fetching clinic data`,
            //       });
            //     } else {
            //       setShouldInit(true); // reload the page essentially
            //       setActiveModal(null);
            //     }
            //     setLoading(false);
            //   })
            //   .catch((e) => {
            //     console.error(e);
            //     setLoading(false);
            //   });
            // },
            showClose: false,
            confirmLabel: "Ok",
            onConfirm: () => {
              setActiveModal(null);
            },
          }}
        >
          <p>To add them to this clinic, click 'Add Drop In'.</p>
        </ConfirmDialog>
      )}
      {activeModal === "pausing" && (
        <ConfirmDialog
          {...{
            open: true,
            title: suspended ? "Enable Bookings" : "Suspend Bookings",
            onConfirm: () => {
              setLoading(true);
              DataClient.call({
                url: `/Clinic/${clinic.id}/ChangeBookingSuspended`,
                method: "PUT",
                params: { isBookingSuspended: !suspended },
              })
                .client.then(async (resClinic) => {
                  if (resClinic.isBookingSuspended === !suspended) {
                    setSuspended(resClinic.isBookingSuspended);
                    setActiveModal(null);
                  } else {
                    throw new Error("Could not change clinic pause status");
                  }
                })
                .catch((e) => {
                  setToast({
                    type: "error",
                    message: e.message,
                  });
                  console.error(e);
                })
                .finally(() => {
                  setLoading(false);
                });
            },
            onClose: () => {
              setActiveModal(null);
            },
          }}
        >
          <p>
            Are you sure you want to{" "}
            <strong> {suspended ? "enable" : "suspend"} bookings</strong> for
            this clinic?
          </p>
        </ConfirmDialog>
      )}
      {showRequiresAdminModal !== null && (
        <ConfirmDialog
          {...{
            open: true,
            title: "Close Clinic",
            onConfirm: () => {
              postSetManualAdminCheck();
            },
            onClose: () => {
              setShowRequiresAdminModal(null);
            },
            closeLabel: "Cancel",
            confirmLabel: showRequiresAdminModal !== 0 ? "OK" : "Close Clinic",
          }}
        >
          <>
            {showRequiresAdminModal > 0 ? (
              // Some req admin msg
              <>
                <p style={{ color: "red" }}>
                  <strong>
                    {showRequiresAdminModal} staff member
                    {showRequiresAdminModal === 1 ? "" : "s"} require
                    {showRequiresAdminModal === 1 ? "s" : ""} attention
                  </strong>
                </p>
                <p>
                  No staff should be left with the status of ‘Booked’ at the end
                  of a clinic. They need to be recorded as either ‘Vaccinated’,
                  ‘DNA’ or ‘Attended But Not Vaccinated’
                </p>
                <p>
                  <strong>
                    To show which staff require attention click ‘OK’ below and
                    then click the red ‘Show Staff Requiring Admin’ button.
                  </strong>
                </p>
                <p>
                  <strong>
                    ONLY CLICK ‘OK’ IF YOU ARE READY TO CLOSE THE CLINIC
                  </strong>
                </p>
                <p>
                  Please note, if after correcting the highlighted staff
                  members, you still see some staff with a status of ‘Booked’ in
                  the full clinic list, this is because they are booked into
                  future clinics, so they do not need any attention in this
                  clinic.
                </p>
              </>
            ) : (
              // None Req admin msg
              <>
                <p>
                  <strong>
                    There are no staff members requiring attention
                  </strong>
                </p>
                <p>All staff in the clinic have been correctly recorded.</p>
                <p>Thank you for all your hard work!</p>
                <p>
                  <strong>
                    Click the 'Close Clinic' button below to close this clinic.
                  </strong>
                </p>
              </>
            )}
          </>
        </ConfirmDialog>
      )}
      {toast && (
        <ToastMessage
          variant={toast.type || "error"}
          message={toast?.message || "Something went wrong"}
          clear={() => {
            setToast(null);
          }}
        />
      )}
      {loading && (
        <div className="interstitial">
          <CircularProgress />
        </div>
      )}
    </>
  );
};
