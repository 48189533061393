import { useState, useEffect, useRef } from "react";
import { Button, Grid, TextField, Tooltip } from "@material-ui/core";
import DropdownInput from "../../../components/FormComponent/dropdown";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { PerformanceRow } from "../DashboardApi";
import DashboardApi from "../DashboardApi";
import clsx from "clsx";
import Fuse from "fuse.js";
import CloudDownload from "@material-ui/icons/CloudDownload";
import { font1 } from "../../../lib/themes/vars";

const color = {
  text: "#333",
  orange: "#fae8de",
  green: "#defae4",
  pink: "#fadef5",
  blue: "#def0fa",
};
const useStyles = makeStyles({
  perfTable: {
    borderCollapse: "collapse",
    minWidth: 650,
    "& $tbody": {
      margin: 10,
    },
    "& $thead $tr $th": {
      color: color.text,
      textAlign: "center",
      borderBottom: "none",
      lineHeight: 1,
      paddingLeft: 7,
      cursor: "pointer",
    },
    "& $tbody $tr $td": {
      color: color.text,
      textAlign: "center",
      borderBottom: "none",
      paddingLeft: 7,
      "&.alignLeftHard": {
        textAlign: "left",
      },
    },
    "& $tbody $tr $td:first-child": {
      textAlign: "left",
    },
    "& $thead $tr first:$th": {
      border: "none",
    },
  },
  blankRow: {
    backgroundColor: "transparent",
    height: 15,
  },
  orangeCell: {
    backgroundColor: color.orange,
    borderRight: "2px solid #fff",
  },
  greenCell: {
    backgroundColor: color.green,
    borderRight: "2px solid #fff",
  },
  pinkCell: {
    backgroundColor: color.pink,
    borderRight: "2px solid #fff",
  },
  blueCell: {
    backgroundColor: color.blue,
    borderRight: "2px solid #fff",
  },
  rowTitleCell: {
    minWidth: 225,
    textAlign: "left",
  },
  compareTitle: {
    minWidth: 225,
  },
  headerGridRow: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  hidden: {
    display: "none",
  },
  dropDownContainer: {
    paddingLeft: 0,
  },
});

export const PerformanceTable = (props: any) => {
  const api = DashboardApi;
  const [perfRows, setPerfRows] = useState([]);
  const [perfRowsSearched, setPerfRowsSearched] = useState([]);
  const [trustRow, setTrustRow] = useState({
    name: "Trust name",
    frontLinePercentage: 0,
    totalPercentage: 0,
  } as PerformanceRow);
  const chartRef = useRef(null);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [orderDirection, setOrderDirection] = useState("asc");

  const GetDropdownOptions = () => {
    const options = [
      { label: "Staff Group", value: "staffGroups" },
      { label: "Job Role", value: "jobRole" },
      { label: "Level 1", value: "level1" },
      { label: "Level 2", value: "level2" },
      { label: "Level 3", value: "level3" },
      { label: "Level 4", value: "level4" },
      { label: "Ethnicity", value: "ethnicity" },
      { label: "Age Group", value: "ageRange" },
    ];
    if (props.trustId === 0) {
      options.unshift({ label: "Trust", value: "trust" });
    }
    return options;
  };
  const [dropdownOptions, setDropDownOptions] = useState(GetDropdownOptions());
  const [selectedDimension, setSelectedDimension] = useState(
    dropdownOptions[0]
  );
  const PerfTableHeaderClick = (e: any) => {
    const headerChosen = e.target.textContent.replace(" ", "");

    if (headerChosen === sortBy) {
      orderDirection === "asc"
        ? setOrderDirection("desc")
        : setOrderDirection("asc");
    }
    console.log(`Ordering by ${headerChosen} ${orderDirection}`);
    setSortBy(headerChosen);
  };

  const SortPerfTable = () => {
    let rows: PerformanceRow[] = Object.assign([], perfRowsSearched);

    switch (sortBy) {
      case "TotalStaff": {
        rows.sort((a: PerformanceRow, b: PerformanceRow) => {
          return orderDirection === "asc"
            ? a.totalStaff - b.totalStaff
            : b.totalStaff - a.totalStaff;
        });
        break;
      }
      case "FrontlineStaff": {
        rows.sort((a: PerformanceRow, b: PerformanceRow) => {
          return orderDirection === "asc"
            ? a.frontlineStaff - b.frontlineStaff
            : b.frontlineStaff - a.frontlineStaff;
        });
        break;
      }
      case "Vaccinated": {
        rows.sort((a: PerformanceRow, b: PerformanceRow) => {
          return orderDirection === "asc"
            ? a.vaccinated - b.vaccinated
            : b.vaccinated - a.vaccinated;
        });
        break;
      }
      case "VaccinatedByTrust": {
        rows.sort((a: PerformanceRow, b: PerformanceRow) => {
          return orderDirection === "asc"
            ? a.vaccinatedByTrust - b.vaccinatedByTrust
            : b.vaccinatedByTrust - a.vaccinatedByTrust;
        });
        break;
      }
      case "HadElsewhere": {
        rows.sort((a: PerformanceRow, b: PerformanceRow) => {
          return orderDirection === "asc"
            ? a.hadElsewhere - b.hadElsewhere
            : b.hadElsewhere - a.hadElsewhere;
        });
        break;
      }
      case "Booked": {
        rows.sort((a: PerformanceRow, b: PerformanceRow) => {
          return orderDirection === "asc"
            ? a.booked - b.booked
            : b.booked - a.booked;
        });
        break;
      }
      case "NoBooking": {
        rows.sort((a: PerformanceRow, b: PerformanceRow) => {
          return orderDirection === "asc"
            ? a.noBooking - b.noBooking
            : b.noBooking - a.noBooking;
        });
        break;
      }
      case "Declined": {
        rows = perfRowsSearched.sort((a: PerformanceRow, b: PerformanceRow) => {
          return orderDirection === "asc"
            ? a.declined - b.declined
            : b.declined - a.declined;
        });
        break;
      }
      case "DNA": {
        rows.sort((a: PerformanceRow, b: PerformanceRow) => {
          return orderDirection === "asc" ? a.dna - b.dna : b.dna - a.dna;
        });
        break;
      }
      case "ABNV": {
        rows.sort((a: PerformanceRow, b: PerformanceRow) => {
          return orderDirection === "asc" ? a.abnv - b.abnv : b.abnv - a.abnv;
        });
        break;
      }
      case "FrontlinePercentage": {
        rows.sort((a: PerformanceRow, b: PerformanceRow) => {
          return orderDirection === "asc"
            ? a.frontLinePercentage - b.frontLinePercentage
            : b.frontLinePercentage - a.frontLinePercentage;
        });
        break;
      }
      case "TotalPercentage": {
        rows.sort((a: PerformanceRow, b: PerformanceRow) => {
          return orderDirection === "asc"
            ? a.totalPercentage - b.totalPercentage
            : b.totalPercentage - a.totalPercentage;
        });
        break;
      }
    }
    setPerfRows(rows);
  };

  useEffect(() => {
    SortPerfTable();
  }, [sortBy, orderDirection]);

  const fetchTotals = () => {
    setLoading(true);
    setTrustRow(null);
    api
      .performanceTrustTotals(
        props.pathogenIds,
        props.onlyFLHCW,
        props.trustId,
        props.excludeL1Ids
      )
      .then((res) => {
        setTrustRow(res);
      })
      // .catch((e) => {
      //   if (!e.handled) {
      //   }
      // })
      .finally(() => setLoading(false));
  };

  const fetchBreakdown = () => {
    setLoading(true);

    api
      .performanceTrustBreakdown(
        selectedDimension.value,
        props.pathogenIds,
        props.onlyFLHCW,
        props.trustId,
        props.excludeL1Ids
      )
      .then((res) => {
        setPerfRows(res);
      })
      // .catch((e) => {
      //   if (!e.handled) {
      //   }
      // })
      .finally(() => setLoading(false));
  };

  const fetchBreakdownDownload = () => {
    const filename = filenameMap[selectedDimension.value];
    api.performanceTrustDownload(
      selectedDimension.value,
      props.pathogenIds,
      props.onlyFLHCW,
      props.trustId,
      props.excludeL1Ids,
      filename
    );
  };

  const filenameMap = {
    trust: "VaccinationTrack Performance Data - Trust",
    staffGroups: "VaccinationTrack Performance Data - Staff Group",
    jobRole: "VaccinationTrack Performance Data - Job Roles",
    level1: "VaccinationTrack Performance Data - Level 1",
    level2: "VaccinationTrack Performance Data - Level 2",
    level3: "VaccinationTrack Performance Data - Level 3",
    level4: "VaccinationTrack Performance Data - Level 4",
    ethnicity: "VaccinationTrack Performance Data - Ethnicity",
    ageRange: "VaccinationTrack Performance Data - Age Group",
  };

  const refreshOptions = () => {
    const options = GetDropdownOptions();
    setDropDownOptions(options);
    // setSelectedDimension(options[0]);
  };
  useEffect(() => {
    refreshOptions();
  }, [props]);
  useEffect(() => {
    fetchTotals();
  }, [api, props]);

  useEffect(() => {
    setPerfRows([]);
    fetchBreakdown();
  }, [api, selectedDimension, props]);

  useEffect(() => {
    setPerfRowsSearched(perfRows);
    setSearch("");
  }, [perfRows]);

  const [search, setSearch] = useState(null);
  useEffect(() => {
    if (!search || !perfRows.length) return;
    // Fuzzy search
    const fuse = new Fuse(perfRows, {
      keys: ["name"],
      threshold: 0.3,
    });
    const results = fuse.search(search)?.map((r) => r.item) || [];
    // console.log("::search", search, perfRows, results);
    setPerfRowsSearched(results);
  }, [search]);

  return (
    <>
      <h2>{props.trustId > 0 ? "Trust" : "System"} Overview</h2>

      <Table className={classes.perfTable} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.rowTitleCell}></TableCell>
            <TableCell
              align="right"
              className={clsx(
                classes.orangeCell,
                props.onlyFLHCW ? classes.hidden : ""
              )}
            >
              Total Staff
            </TableCell>
            <TableCell align="right" className={classes.orangeCell}>
              Frontline
              <br />
              Staff
            </TableCell>
            <TableCell align="right" className={classes.greenCell}>
              Vaccinated
            </TableCell>
            {props.pathogenIds.length == 1 && (
              <>
                <TableCell align="right" className={classes.greenCell}>
                  Vaccinated
                  <br />
                  By Trust
                </TableCell>
                <TableCell align="right" className={classes.greenCell}>
                  Had
                  <br />
                  Elsewhere
                </TableCell>
                <TableCell align="right" className={classes.greenCell}>
                  Booked
                </TableCell>
                <TableCell align="right" className={classes.pinkCell}>
                  No Booking
                </TableCell>
                <TableCell align="right" className={classes.pinkCell}>
                  Declined
                </TableCell>
                <TableCell align="right" className={classes.pinkCell}>
                  DNA
                </TableCell>
                <TableCell align="right" className={classes.pinkCell}>
                  ABNV
                </TableCell>
              </>
            )}
            <TableCell align="right" className={classes.blueCell}>
              Frontline
              <br />
              Percentage
            </TableCell>
            <TableCell
              align="right"
              className={clsx(
                classes.blueCell,
                props.onlyFLHCW ? classes.hidden : ""
              )}
            >
              Total
              <br />
              Percentage
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.blankRow}></TableRow>
          {trustRow != null && (
            <TableRow>
              <TableCell className={classes.rowTitleCell}>
                {trustRow.name}
              </TableCell>
              <TableCell
                align="right"
                className={clsx(
                  classes.orangeCell,
                  props.onlyFLHCW ? classes.hidden : ""
                )}
              >
                {" "}
                {trustRow.totalStaff}{" "}
              </TableCell>

              <TableCell align="right" className={classes.orangeCell}>
                {trustRow.frontlineStaff}
              </TableCell>
              <TableCell align="right" className={classes.greenCell}>
                {trustRow.vaccinated}
              </TableCell>
              {props.pathogenIds.length == 1 && (
                <>
                  <TableCell align="right" className={classes.greenCell}>
                    {trustRow.vaccinatedByTrust}
                  </TableCell>
                  <TableCell align="right" className={classes.greenCell}>
                    {trustRow.hadElsewhere}
                  </TableCell>
                  <TableCell align="right" className={classes.greenCell}>
                    {trustRow.booked}
                  </TableCell>
                  <TableCell align="right" className={classes.pinkCell}>
                    {trustRow.noBooking}
                  </TableCell>
                  <TableCell align="right" className={classes.pinkCell}>
                    {trustRow.declined}
                  </TableCell>
                  <TableCell align="right" className={classes.pinkCell}>
                    {trustRow.dna}
                  </TableCell>
                  <TableCell align="right" className={classes.pinkCell}>
                    {trustRow.abnv}
                  </TableCell>
                </>
              )}
              <TableCell align="right" className={classes.blueCell}>
                {trustRow.frontLinePercentage.toFixed(1)}%
              </TableCell>
              <TableCell
                align="right"
                className={clsx(
                  classes.blueCell,
                  props.onlyFLHCW ? classes.hidden : ""
                )}
              >
                {trustRow.totalPercentage.toFixed(1)}%
              </TableCell>
            </TableRow>
          )}
          <TableRow className={classes.blankRow}></TableRow>
          <TableRow className={classes.headerGridRow}>
            <TableCell className={classes.rowTitleCell}>
              <Grid className={classes.compareTitle}>
                <h2 className="cell-heading" style={{ fontFamily: font1 }}>
                  Compare Performance
                </h2>
              </Grid>
            </TableCell>
            <TableCell colSpan={4}>
              <Grid
                className={clsx(
                  classes.dropDownContainer
                )}
              >
                <DropdownInput
                  inputFieldProps={{
                    id: "dropdown_perf_chart",
                    name: "dropdown_perf_chart",
                    value: selectedDimension,
                  }}
                  styles={{ marginTop: "0", menuList: { maxHeight: "400px" } }}
                  options={dropdownOptions}
                  setDropDownValue={(option: any) => {
                    setSelectedDimension(option);
                  }}
                />
              </Grid>
            </TableCell>
            <TableCell colSpan={3} align="left" className="alignLeftHard">
              <Button
                onClick={fetchBreakdownDownload}
                color="primary"
                startIcon={<CloudDownload />}
                variant="contained"
              >
                Download Performance Data
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
        <TableHead className="perf-table-sticky-head">
          <TableRow>
            <TableCell
              className={classes.rowTitleCell}
              // [dd#2339] https://2-app.donedone.com/6110/project/17407/task/717306
              style={{ backgroundColor: "#EFF1F8" }}
            >
              <TextField
                onChange={(e: any) => setSearch(e.target.value)}
                // onChange={debounce((e: any) => setSearch(e.target.value), 500)}
                // Thinking about it, debounce is unnecessary at this point (and breaks two-way binding anyway)
                value={search}
                // Might want an outline? Whatever I choose will be wrong..
                // Update: It was! Shocker. "More search bary" https://2-app.donedone.com/6110/project/17407/task/717306
                variant="outlined"
                fullWidth
                hiddenLabel
                placeholder="Search.."
                className="search-textfield"
              />
            </TableCell>
            <Tooltip title="Sort by Total Staff" arrow>
              <TableCell
                className={clsx(
                  classes.orangeCell,
                  props.onlyFLHCW ? classes.hidden : ""
                )}
                onClick={PerfTableHeaderClick}
              >
                Total Staff
              </TableCell>
            </Tooltip>
            <Tooltip title="Sort by Frontline Staff" arrow>
              <TableCell
                className={clsx(classes.orangeCell)}
                onClick={PerfTableHeaderClick}
              >
                Frontline
                <br />
                Staff
              </TableCell>
            </Tooltip>
            <Tooltip title="Sort by Vaccinated" arrow>
              <TableCell
                className={classes.greenCell}
                onClick={PerfTableHeaderClick}
              >
                Vaccinated
              </TableCell>
            </Tooltip>
            {props.pathogenIds.length == 1 && (
              <>
                <Tooltip title="Sort by Vaccinated by Trust" arrow>
                  <TableCell
                    className={classes.greenCell}
                    onClick={PerfTableHeaderClick}
                  >
                    Vaccinated
                    <br />
                    By Trust
                  </TableCell>
                </Tooltip>
                <Tooltip title="Sort by Had Elsewhere" arrow>
                  <TableCell
                    className={classes.greenCell}
                    onClick={PerfTableHeaderClick}
                  >
                    Had
                    <br />
                    Elsewhere
                  </TableCell>
                </Tooltip>
                <Tooltip title="Sort by Booked" arrow>
                  <TableCell
                    className={classes.greenCell}
                    onClick={PerfTableHeaderClick}
                  >
                    Booked
                  </TableCell>
                </Tooltip>
                <Tooltip title="Sort by No Booking" arrow>
                  <TableCell
                    className={classes.pinkCell}
                    onClick={PerfTableHeaderClick}
                  >
                    No Booking
                  </TableCell>
                </Tooltip>
                <Tooltip title="Sort by Declined" arrow>
                  <TableCell
                    className={classes.pinkCell}
                    onClick={PerfTableHeaderClick}
                  >
                    Declined
                  </TableCell>
                </Tooltip>
                <Tooltip title="Sort by DNA" arrow>
                  <TableCell
                    className={classes.pinkCell}
                    onClick={PerfTableHeaderClick}
                  >
                    DNA
                  </TableCell>
                </Tooltip>
                <Tooltip title="Sort by ABNV" arrow>
                  <TableCell
                    className={classes.pinkCell}
                    onClick={PerfTableHeaderClick}
                  >
                    ABNV
                  </TableCell>
                </Tooltip>
              </>
            )}
            <Tooltip title="Sort by Frontline Percentage" arrow>
              <TableCell
                className={classes.blueCell}
                onClick={PerfTableHeaderClick}
              >
                Frontline
                <br />
                Percentage
              </TableCell>
            </Tooltip>
            <Tooltip title="Sort by Total Percentage" arrow>
              <TableCell
                className={clsx(
                  classes.blueCell,
                  props.onlyFLHCW ? classes.hidden : ""
                )}
                onClick={PerfTableHeaderClick}
              >
                Total
                <br />
                Percentage
              </TableCell>
            </Tooltip>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.blankRow}></TableRow>
          {perfRowsSearched.map((row: PerformanceRow, i: number) => (
            <TableRow key={`tablerow-${i}`}>
              <TableCell className={classes.rowTitleCell}>{row.name}</TableCell>
              <TableCell
                className={clsx(
                  classes.orangeCell,
                  props.onlyFLHCW ? classes.hidden : ""
                )}
              >
                {row.totalStaff}
              </TableCell>
              <TableCell className={classes.orangeCell}>
                {row.frontlineStaff}
              </TableCell>
              <TableCell className={classes.greenCell}>
                {row.vaccinated}
              </TableCell>
              {props.pathogenIds.length == 1 && (
                <>
                  <TableCell className={classes.greenCell}>
                    {row.vaccinatedByTrust}
                  </TableCell>
                  <TableCell className={classes.greenCell}>
                    {row.hadElsewhere}
                  </TableCell>
                  <TableCell className={classes.greenCell}>
                    {row.booked}
                  </TableCell>
                  <TableCell className={classes.pinkCell}>
                    {row.noBooking}
                  </TableCell>
                  <TableCell className={classes.pinkCell}>
                    {row.declined}
                  </TableCell>
                  <TableCell className={classes.pinkCell}>{row.dna}</TableCell>
                  <TableCell className={classes.pinkCell}>{row.abnv}</TableCell>
                </>
              )}
              <TableCell className={classes.blueCell}>
                {row.frontLinePercentage.toFixed(1)}%
              </TableCell>
              <TableCell
                className={clsx(
                  classes.blueCell,
                  props.onlyFLHCW ? classes.hidden : ""
                )}
              >
                {row.totalPercentage.toFixed(1)}%
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
