import { Button, CircularProgress, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import ToastMessage from "../../../../components/Universal/toast_message";
import usePrefersReducedMotion from "../../../../lib/hooks/usePrefersReducedMotion";
import DataClient from "../../../../lib/services/api/DataClient";
import {
  validateNhsNumber,
  validatePhoneNumber,
} from "../../../../lib/validationRules";
import ConfirmDialog from "../../ConfirmDialog";
import AppointmentDetails from "./AppointmentDetails";
import Checkbox from "./Checkbox";
import styles from "./form.module.scss";
import useConsentQuestions from "./useConsentQuestions";
import useDelayedRedirect from "./useDelayedRedirect";
import DropdownInput from "../../../../components/FormComponent/dropdown";
import {
  PathogenOption,
  Pathogens,
  getTrustPathogenIds,
  getPathogenCombinations,
} from "../../../../lib/pathogens";
import BoosterDateChecker, { DateContext } from "../BoosterDateChecker";
import { DateTime } from "luxon";
import { getSites } from "./AppointmentDetails/stateMachine";
import ReactDOM from "react-dom";
import { getMe } from "../../../../lib/services/TrustSwitcher";

const QUESTION_PREFIX = "question-";
const CHECKLIST_FIELDGROUP = "consentChecklist";
const DROP_IN = "drop-in";

const useStyles = makeStyles({
  root: {
    background: "#009fe3",
  },
});

type BookingFormProps = {
  trustWorkerId: number;
  nhsNumber?: string;
  bookingForMyself?: boolean;
  reschedule?: number;
  onSuccess?: (...args: any[]) => void;
  pathogens?: Pathogens[];
  dateOverride?: DateTime;
  isForStaff?: boolean;
};

export default function BookingForm({
  trustWorkerId,
  nhsNumber = "",
  bookingForMyself,
  reschedule = null,
  onSuccess,
  pathogens: unbookedVaccinations,
  dateOverride = null,
  isForStaff = false,
}: BookingFormProps): JSX.Element {
  const {
    control,
    formState: { errors, isSubmitSuccessful, isSubmitting },
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm();
  const [isBookingSuccessful, setBookingSuccessful] = useState(false);
  const [isBookingError, setBookingError] = useState<boolean | string[]>(false);
  const [disableRecheduleConfirm, setDisableRescheduleConfirm] =
    useState(false);
  const redirect = useDelayedRedirect("/my-appointments", 2);
  const [vaccinationType, setVaccinationType] = useState<PathogenOption>({
    label: "",
    value: null,
  });
  const {
    consentQuestions,
    confirmationQuestions,
    errors: questionErrors,
    reload,
    isLoading,
    loadingTime,
  } = useConsentQuestions(trustWorkerId, vaccinationType.value);
  const consentQuestionsFlu = consentQuestions.filter((q) =>
    q.pathogens.every((p) => p === Pathogens.Flu)
  );
  const consentQuestionsCovid = consentQuestions.filter((q) =>
    q.pathogens.every((p) => p === Pathogens.Covid)
  );

  useEffect(() => {
    const value = watch();
    reset({
      ...value,
      [`${CHECKLIST_FIELDGROUP}-Prebooking`]: confirmationQuestions.reduce(
        (p, c) => ({
          ...p,
          [`${QUESTION_PREFIX}${c.id}`]: false,
        }),
        {}
      ),
    });
  }, [confirmationQuestions, reset, watch]);

  // Populate NHS number using /me, gently resetting whenever it finished fetching
  useEffect(() => {
    (async () => {
      if (bookingForMyself) {
        const me = await getMe();
        nhsNumber = me.currentTrust?.nhsNumber ?? "";
      }
      // else use passed prop (e.g. from staff list table
      reset({ ...getValues(), nhsNumber });
    })();
  }, [reset]);

  const onSubmit = async (data: any) => {
    const req = {
      nhsNumber: data.nhsNumber?.replaceAll(" ", ""),
      mobileNumber: data.mobileNumber?.replaceAll(" ", ""),
      consentChecklist: data[CHECKLIST_FIELDGROUP]
        ? Object.entries(data[CHECKLIST_FIELDGROUP])
            .map(([key, value]) => ({
              trustConsentQuestionId: consentQuestions.find(
                (q) => key.replace(QUESTION_PREFIX, "") === `${q.id}`
              )?.id,
              answer: value,
            }))
            .filter((q) => q.trustConsentQuestionId)
        : [],
      consentStatus: true,
      clinicId: data.appointmentDetails.clinicId,
      timeFrom:
        data.appointmentDetails.timeFrom === DROP_IN
          ? null
          : data.appointmentDetails.timeFrom,
      trustWorkerId,
      pathogens:
        unbookedVaccinations?.length > 1
          ? vaccinationType.value
          : [unbookedVaccinations[0]],
    };
    const endpoint =
      reschedule !== null ? "/Reservation/Reschedule" : "/Reservation";
    await DataClient.postData(endpoint, req).then((res) => {
      if (res.success) {
        if (onSuccess) {
          onSuccess();
        } else {
          setBookingSuccessful(true);
          redirect();
        }
      } else {
        console.error(res);
        throw Error("Form failed to submit");
      }
    });
  };

  const checkStatus = (statuses: boolean[]) => statuses.every((s) => s);
  const [secondVaccinationDate, setSecondVaccinationDate] =
    useState<DateTime | null>(dateOverride);
  useEffect(() => {
    // If dateOverride kicks in randomly, e.g. getMe finding a second covid date, update the state
    if (dateOverride) {
      setSecondVaccinationDate(dateOverride);
    }
  }, [dateOverride, secondVaccinationDate]);
  const watchConfirmation = confirmationQuestions.length
    ? watch(
        confirmationQuestions.map(
          (q) => `${CHECKLIST_FIELDGROUP}-Prebooking.${QUESTION_PREFIX}${q.id}`
        )
      )
    : false;
  const isConfirmed = watchConfirmation && watchConfirmation.every((v) => v);
  // console.log(
  //   "::watch",
  //   {
  //     isConfirmed,
  //     vaccinationType,
  //     dateOverride: dateOverride?.toISO(),
  //     secondVaccinationDate: secondVaccinationDate?.toISO(),
  //   },
  //   watchConfirmation && watchConfirmation.every((v) => v),
  //   vaccinationType?.value?.every((p) => p === Pathogens.Flu)
  // );

  // Changing the date needs to reset select clinic's startDate context, even after filling in later parts of the form, so in order to trigger this update let's reset the consent question checkboxes (which is intuitive to be fair, as it resets the form back to that point in the user's journey)
  useEffect(() => {
    confirmationQuestions?.forEach((q) => {
      setValue(
        `${CHECKLIST_FIELDGROUP}-Prebooking.${QUESTION_PREFIX}${q.id}`,
        false
      );
    });
  }, [secondVaccinationDate]);
  const disabled =
    isSubmitting ||
    isSubmitSuccessful ||
    isLoading ||
    Object.entries(questionErrors).length > 0 ||
    !isConfirmed;

  const watchDetails = watch("appointmentDetails");
  const isAppointmentSelected =
    watchDetails?.clinicId && watchDetails?.timeFrom;

  const isFineWithScrolling = !usePrefersReducedMotion();
  const consentRef = useRef<HTMLFieldSetElement>();
  const [isYetToScroll, setYetToScroll] = useState(true);

  useEffect(() => {
    if (isAppointmentSelected && isYetToScroll && isFineWithScrolling) {
      setTimeout(() => {
        consentRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }, 400);
      setYetToScroll(false);
    }
  }, [isAppointmentSelected, isYetToScroll, isFineWithScrolling]);

  const classes = useStyles();
  const formRef = useRef<HTMLFormElement>();
  const submitForm = (e: SyntheticEvent) => {
    handleSubmit(onSubmit)(e).catch((error) => {
      if (
        error?.response?.status === 400 ||
        error?.response?.status === 404 ||
        error?.response?.status === 409
      ) {
        setBookingError(
          error.response?.data?.results ||
            error.response?.data?.errors || [error.response?.data]
        );
      } else {
        console.error(error);
        setBookingError(true);
      }
    });
  };

  const [availableVaccinationOptions, setAvailableVaccinationOptions] =
    useState<PathogenOption[]>(null);

  useEffect(() => {
    const unbookedCombinations = getPathogenCombinations(
      unbookedVaccinations.map((x) => ({ id: x, name: Pathogens[x] }))
    ).map((x: { id: number[]; name: string }) => ({
      label: x.name,
      value: x.id,
    })) as PathogenOption[];
    Promise.all(
      unbookedCombinations
        .map((x) => ({ pathogens: x.value }))
        .map((params) => getSites(params))
    ).then((responses) => {
      const combinedOptions = unbookedCombinations.filter(
        (_x, i) => responses[i].results.length
      );

      const selectedVaccinationType =
        combinedOptions.length >= 1
          ? combinedOptions.slice(-1)[0]
          : {
              label: "",
              value: null,
            };

      ReactDOM.unstable_batchedUpdates(() => {
        setAvailableVaccinationOptions(combinedOptions);
        setVaccinationType(selectedVaccinationType);
      });
    });
  }, [
    setAvailableVaccinationOptions,
    setVaccinationType,
    unbookedVaccinations,
  ]);

  const trustPathogens = getTrustPathogenIds();
  const isMultiplePathogenTrust = trustPathogens?.length > 1;
  const [showRescheduleDialog, setShowDialog] = useState(false);
  const [eventCache, setEventCache] = useState(null);
  const isReschedule = reschedule !== null;
  const [boostDateSelected, setBoostDateSelected] = useState(null);

  const selectPathogenList = availableVaccinationOptions ?? [];
  const noClinics = availableVaccinationOptions?.length === 0;
  const isSingleVaccineOption = availableVaccinationOptions?.length === 1;

  return (
    <>
      {!availableVaccinationOptions ? (
        <LinearProgress />
      ) : (
        <>
          <form ref={formRef} className={styles.form}>
            {noClinics ? (
              <h2>
                There are no clinics available right now. Please check back
                later.
              </h2>
            ) : (
              <>
                {isMultiplePathogenTrust && (
                  <>
                    {!isReschedule && !isSingleVaccineOption && (
                      <h2 style={{ marginBottom: "-24px" }}>
                        Select the vaccination(s) you’d like to book
                      </h2>
                    )}
                    <div style={{ maxWidth: "30rem", marginTop: 0 }}>
                      {isSingleVaccineOption && (
                        <h3 style={{ marginBottom: "-24px" }}>
                          Vaccination Type
                        </h3>
                      )}
                      <DropdownInput
                        inputFieldProps={{
                          id: "appointmentVaccinationType",
                          name: "vaccinationType",
                          value: vaccinationType,
                        }}
                        options={selectPathogenList}
                        setDropDownValue={setVaccinationType}
                        disabled={isReschedule || isSingleVaccineOption}
                      />
                    </div>
                  </>
                )}

                {vaccinationType.value.includes(Pathogens.Covid) && (
                  <div style={{ marginTop: "8px" }}>
                    <DateContext.Provider
                      value={{ setDate: setSecondVaccinationDate }}
                    >
                      <BoosterDateChecker
                        dateOverride={dateOverride}
                        isForStaff={isForStaff}
                      />
                    </DateContext.Provider>
                  </div>
                )}

                {
                  <>
                    <fieldset
                      className={styles.confirmation}
                      disabled={isSubmitSuccessful}
                    >
                      <legend>
                        <h2>Pre-screening</h2>
                      </legend>
                      <div className="wrapper-text">
                        <p className={styles.important}>
                          <span className={styles.moron_proof}>
                            Please confirm ALL the following statements are
                            true.
                          </span>
                          <br />
                          If you are unable to confirm all the statements, then
                          you cannot book an appointment at this time. Please
                          contact your vaccination administrator.
                        </p>
                      </div>
                      <div className={styles.preBookingQuestions}>
                        {vaccinationType.value.includes(Pathogens.Flu) ? (
                          <div>
                            <h3>{Pathogens[Pathogens.Flu]}</h3>
                            {confirmationQuestions
                              .filter((q) =>
                                q.pathogens.every((p) => p === Pathogens.Flu)
                              )
                              .map((q) => (
                                <Checkbox
                                  key={`confirm-${q.id}`}
                                  register={register}
                                  id={`${CHECKLIST_FIELDGROUP}-Prebooking.${QUESTION_PREFIX}${q.id}`}
                                  label={q.question}
                                />
                              ))}
                          </div>
                        ) : null}

                        {vaccinationType.value.includes(Pathogens.Covid) ? (
                          <div>
                            <h3>{Pathogens[Pathogens.Covid]}</h3>
                            {confirmationQuestions
                              .filter((q) =>
                                q.pathogens.every((p) => p === Pathogens.Covid)
                              )
                              .map((q) => (
                                <Checkbox
                                  key={`confirm-${q.id}`}
                                  register={register}
                                  id={`${CHECKLIST_FIELDGROUP}-Prebooking.${QUESTION_PREFIX}${q.id}`}
                                  label={q.question}
                                />
                              ))}
                          </div>
                        ) : null}
                      </div>
                      {isLoading && loadingTime !== 0 && <CircularProgress />}
                      {questionErrors.confirmationError && (
                        <span className={styles.formError} role="alert">
                          Could not load the confirmation questions{" "}
                          <button
                            type="button"
                            onClick={() => {
                              reload();
                            }}
                          >
                            Try again?
                          </button>
                        </span>
                      )}
                    </fieldset>
                    {isConfirmed && vaccinationType?.value && (
                      <div>
                        <AppointmentDetails
                          control={control}
                          disabled={disabled}
                          pathogens={vaccinationType.value}
                          secondVaccinationDate={secondVaccinationDate}
                        />
                        {errors.appointmentDetails && (
                          <span className={styles.formError} role="alert">
                            {errors.appointmentDetails?.message}
                          </span>
                        )}
                      </div>
                    )}
                    {checkStatus([isConfirmed, isAppointmentSelected]) &&
                    ((vaccinationType.value.includes(Pathogens.Flu) &&
                      consentQuestionsFlu.length) ||
                      (vaccinationType.value.includes(Pathogens.Covid) &&
                        consentQuestionsCovid.length)) ? (
                      <fieldset
                        className={styles.consent}
                        disabled={disabled}
                        ref={consentRef}
                      >
                        <legend>
                          <h2>Consent</h2>
                        </legend>
                        <span className={styles.important}>
                          Please confirm if the following statements are true.
                        </span>
                        <p className={styles.consentInfo}>
                          If you answer no to any of these statements, please
                          still attend your appointment.
                          <br />
                          Further advice or information may be needed prior to
                          vaccination or you may be referred to your GP.
                        </p>
                        {isLoading && loadingTime !== 0 && <CircularProgress />}
                        {vaccinationType.value.includes(Pathogens.Flu) &&
                        consentQuestionsFlu.length ? (
                          <div>
                            <h3>{Pathogens[Pathogens.Flu]}</h3>
                            <div className={styles.questions}>
                              {consentQuestionsFlu.map((q) => (
                                <Checkbox
                                  key={`consent-${q.id}`}
                                  register={register}
                                  id={`${CHECKLIST_FIELDGROUP}.${QUESTION_PREFIX}${q.id}`}
                                  label={q.question}
                                />
                              ))}
                            </div>
                          </div>
                        ) : null}
                        {vaccinationType.value.includes(Pathogens.Covid) &&
                        consentQuestionsCovid.length ? (
                          <div>
                            <h3>{Pathogens[Pathogens.Covid]}</h3>
                            <div className={styles.questions}>
                              {consentQuestionsCovid.map((q) => (
                                <Checkbox
                                  key={`consent-${q.id}`}
                                  register={register}
                                  id={`${CHECKLIST_FIELDGROUP}.${QUESTION_PREFIX}${q.id}`}
                                  label={q.question}
                                />
                              ))}
                            </div>
                          </div>
                        ) : null}
                      </fieldset>
                    ) : null}
                    {questionErrors.consentError && (
                      <span className={styles.formError} role="alert">
                        Could not load the consent questions{" "}
                        <button
                          type="button"
                          onClick={() => {
                            reload();
                          }}
                        >
                          Try again?
                        </button>
                      </span>
                    )}
                    {checkStatus([isConfirmed, isAppointmentSelected]) && (
                      <fieldset className={styles.details} disabled={disabled}>
                        <legend>
                          <h2 className={styles.mbZero}>Your Details</h2>
                        </legend>
                        <label>
                          NHS Number
                          <input
                            title="Your NHS number is 10 characters long. You may only enter numbers and spaces."
                            {...register("nhsNumber", {
                              validate: validateNhsNumber,
                            })}
                          />
                          {errors.nhsNumber && (
                            <span role="alert">{errors.nhsNumber.message}</span>
                          )}
                          <a
                            className={styles.helpfulLink}
                            href="https://www.nhs.uk/nhs-services/online-services/find-nhs-number/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Click here to find your NHS number
                          </a>
                        </label>
                        <label>
                          <span>
                            Mobile Number{" "}
                            <span className={styles.labelNote}>
                              (Optional, to receive an SMS reminder)
                            </span>
                          </span>
                          <input
                            title="You may only enter numbers and spaces for your mobile number."
                            {...register("mobileNumber", {
                              pattern: {
                                value: /^[0-9\s]*$/,
                                message:
                                  "Please only enter numbers and spaces.",
                              },
                              validate: validatePhoneNumber,
                            })}
                          />
                          {errors.mobileNumber && (
                            <span role="alert">
                              {errors.mobileNumber.message}
                            </span>
                          )}
                        </label>
                      </fieldset>
                    )}

                    {checkStatus([isConfirmed, isAppointmentSelected]) && (
                      <div>
                        <div className={styles.errorSpacer}>
                          {Object.entries(errors).length > 0 ? (
                            <span className={styles.formError} role="alert">
                              The booking form has errors. Please fix them
                              before resubmitting.
                            </span>
                          ) : null}
                          {isBookingSuccessful ? (
                            <ToastMessage
                              variant="success"
                              message={"Booking succesful!"}
                              clear={() => {
                                setBookingSuccessful(false);
                              }}
                            />
                          ) : null}
                        </div>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={disabled}
                          className={classes.root}
                          onClick={(e) => {
                            if (reschedule) {
                              setShowDialog(true);
                              setEventCache(e);
                            } else {
                              submitForm(e);
                            }
                            e.preventDefault();
                          }}
                        >
                          Confirm Appointment
                        </Button>
                      </div>
                    )}
                  </>
                }
              </>
            )}
          </form>
          <ConfirmDialog
            title="Reschedule Appointment"
            open={showRescheduleDialog}
            confirmLabel="Reschedule"
            disableConfirm={disableRecheduleConfirm}
            onConfirm={() => {
              setDisableRescheduleConfirm(true);
              setTimeout(() => {
                submitForm(eventCache);
                setShowDialog(false);
              }, 300);
            }}
            onClose={() => {
              setShowDialog(false);
            }}
          >
            <p>
              Booking this appointment will cancel your old one. Are you sure
              you want to reschedule?
            </p>
          </ConfirmDialog>
          {isBookingError ? (
            <ToastMessage
              variant="error"
              message={
                Array.isArray(isBookingError)
                  ? isBookingError.join(" ")
                  : "Failed to make booking"
              }
              clear={() => {
                setBookingError(false);
              }}
            />
          ) : null}
        </>
      )}
    </>
  );
}
