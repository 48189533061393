import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Switch,
} from "@material-ui/core";

import { createTheme } from "@material-ui/core/styles";
import { RotateLeft, Error } from "@material-ui/icons";
import { ThemeProvider } from "@material-ui/styles";
import { endsWith, merge, startCase } from "lodash";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import CustomDebouncedSearch from "../../../../components/CustomDebouncedSearch";
import { FlagIcons } from "../../../../components/FlagIcons";
import { humanDate } from "../../../../lib/common_utils";
import {
  enumAssignmentStatusesFilterable,
  enumFlagsFilterable,
  enumFrontLineFilterable,
  enumRolesFilterableExtra,
  enumsVaccinationStatusObsolete,
  enumVaccinationStatus,
  enumVaccinationStatusFilterable,
  filterObsoleteEnumsFromArray,
  SecurityRoleEnum,
} from "../../../../lib/enums";
import {
  enumKeyByValue,
  enumToArray,
  filterHeterogenous,
} from "../../../../lib/enumManipulations";
import {
  Pathogens,
  trustInvolvesCovid,
  trustInvolvesFlu,
} from "../../../../lib/pathogens";
import {
  overridesGenericCells,
  overridesTableSearchBox,
  overridesTableHackActions,
  overridesTableGenericColors,
  overridesMain,
  overridesTableFilters,
} from "../../../../lib/themes/overrides";
import { palette } from "../../../../lib/themes/vars";
import ComplianceFilters from "./ComplianceFilters";
import TableToolbar from "./TableToolbar";
import { TableToolbarSelect } from "./TableToolbarSelect";
import Help from "../../../../components/Help/Help";
const useStyles = makeStyles({
  tableOverrides: {
    // Almost impossible to override these stupid paddings built into the plugin!
    "& th:first-child, & td:first-child": {
      borderRadius: "0.5em 0 0 0.5em",
    },
    "& th:last-child, & td:last-child": {
      borderRadius: "0 0.5em 0.5em 0",
    },
    "& tr:not(:hover)": {
      backgroundColor: "#fff",
    },
    "& [role='toolbar']": {
      paddingLeft: 0,
      paddingRight: 0,
    },
    "& [type='checkbox']": {
      backgroundColor: "#fff",
    },
  },
});
const roleMap = {
  Staff: "S",
  Vaccinator: "V",
  "Trust Admin": "TA",
  "Clinic Admin": "CA",
  Admin: "A",
};
const setFilterChipProps =
  (Pathogens, complianceFiltersEnabled) => (colIndex, colName, data) => {
    return {
      style: { display: complianceFiltersEnabled ? "none" : "flex" },
      variant: "outlined",
      "data-theme-id": endsWith(colName, "Flu")
        ? Pathogens["Flu"]
        : endsWith(colName, "Covid")
          ? Pathogens["Covid"]
          : null,
    };
  };
function Table({
  header,
  showAlertBoxes,
  readOnly = false,
  styleOverrides = {},
  pathogens,
  optionsOverrides = {},
  complianceFiltersEnabled = false,
  complianceFiltersPathogenIds,
  downloadReportEnabled = false,
  items = [],
  filter,
  totalCount,
  renderRowActions,
  setFilter,
  setExternalActiveModal,
  buttonsToShow,
  onRowsArchive,
  onRowsRestore,
  onRowsHardDelete,
  onRowsApprove,
  onRowsReject,
  onRowsHardDeleteSR,
  onFrontLineChange,
  onImmformChange,
  isLoading,
  alertBoxCounts,
}) {
  const classes = useStyles();
  const theme = createTheme({
    palette,
    overrides: merge(
      {},
      overridesMain,
      overridesGenericCells,
      overridesTableGenericColors,
      overridesTableSearchBox,
      overridesTableFilters,
      overridesTableHackActions,
      styleOverrides,
    ),
  });
  const [columnState, setColumnState] = useState({
    name: true,
    dateOfBirth: true,
    staffGroupName: true,
    jobRoleName: true,
    emailAddress: true,
    role: true,
    isFrontLine: true,
    isImmform: true,
    employeeNumber: true,
    pathogens: true,
  });
  const [options, setOptions] = useState();
  const [columns, setColumns] = useState();
  //
  // COLUMNSTATE
  //
  useEffect(() => {
    if (!pathogens) return; // loading essentially
    // Add vaccinationStatusFlu/Covid to columnState if appropriate
    setColumnState({
      ...columnState,
      vaccinationStatusFlu: pathogens?.includes(1),
      vaccinationStatusCovid: pathogens?.includes(2),
    });
  }, [setColumnState, pathogens]);
  //
  // COLUMNS
  //
  useEffect(() => {
    // setColumns was happening waaay to much and leaking
    if (!pathogens || isLoading) return;
    // console.log(":: ~ COLUMNS", { isLoading, itemsCount: items.length });
    // These get magicked later into reservationId, vaccinationStatusFlu, etc which don't all exist but yeah
    // These columns are processed and appended with the pathogen name. If the column is not pathogen specific, then consider placing then in the columns processing array
    const pathogenColumns = [
      {
        name: "reservationId",
        label: "Booking Id",
        options: {
          display: "excluded",
        },
      },
      {
        name: "bookingDate",
        label: "Booked Date",
        options: {
          render: (v) => v && humanDate(v),
        },
      },
      {
        name: "bookingTime",
        label: "Booked Time",
      },
      {
        name: "hospitalSiteName",
        label: "Vaccination Site",
      },
      {
        name: "clinicLocationName",
        label: "Vaccination Clinic",
      },
      {
        name: "vaccinationDate",
        label: "Vaccination Date",
        options: {
          render: (v) => v && humanDate(v),
        },
      },
      {
        name: "vaccinationStatus",
        label: "Status",
        options: {
          display: true, // by default
        },
      },
      {
        name: "declineReason",
        label: "Decline Reason",
      },
      {
        name: "declineDescription",
        label: "Decline / Had Elsewhere / Exemption Info",
      },
      {
        name: "hadElsewhereSite",
        label: "Had Elsewhere Site",
      },
      {
        name: "hadElsewhereLocation",
        label: "Had Elsewhere Location",
      },
      {
        name: "hadElsewhereDate",
        label: "Had Elsewhere Date",
        options: {
          render: (v) => v && humanDate(v),
        },
      },
      {
        name: "hadElsewhereVaccinationType",
        label: "Had Elsewhere Vaccination Type",
      },
      {
        name: "hadElsewhereVaccinationBatchNumber",
        label: "Had Elsewhere Vaccination Batch Number",
      },
      {
        name: "injectionSite",
        label: "Injection Site",
      },
      {
        name: "prescribingMethod",
        label: "Prescribing Method",
      },
      {
        name: "allergyStatus",
        label: "Allergy Status",
      },
      {
        name: "batchNumber",
        label: "Batch Number",
      },
      {
        name: "vaccineType",
        label: "Vaccine Type",
      },
      {
        name: "otherInformation",
        label: "Other Information",
      },
      {
        name: "vaccinator",
        label: "Vaccinator",
      },
      {
        name: "vaccinationTime",
        label: "Vaccination Time",
      },
      {
        name: "expiryDate",
        label: "Expiry Date",
        options: {
          render: (v) => {
            if (!v) return null;
            return humanDate(v);
          },
        },
      },
      {
        name: "consentStatus",
        label: "Consent Status",
        options: {
          display: "excluded",
        },
      },
    ];
    let columnsProcessing = [
      {
        name: "id",
        options: {
          display: "excluded",
        },
      },
      ...(trustInvolvesFlu()
        ? [
            {
              // These two fields don't exist in the data per-se but do as search parameters so this adds filter dropdowns
              name: "vaccinationStatusIdFlu",
              label: "Flu Vaccination Status",
              options: {
                display: "excluded",
                filter: true,
                filterType: "dropdown",
                filterList: filter.vaccinationStatusIdFlu
                  ? [
                      enumVaccinationStatusFilterable[
                        filter.vaccinationStatusIdFlu
                      ],
                    ]
                  : [], // default chosen options (none)
                filterOptions: {
                  // These get sorted alphabetically it seems, but the whole render can be overrided if needed https://github.com/gregnb/mui-datatables/blob/master/examples/customize-filter/index.js#L66
                  names: filterObsoleteEnumsFromArray(
                    enumToArray(enumVaccinationStatusFilterable),
                    enumsVaccinationStatusObsolete,
                  ).map((s) => s?.name),
                },
                customFilterListOptions: {
                  render: (v) => `Flu Vaccination Status: ${v}`,
                },
              },
            },
          ]
        : []),
      ...(trustInvolvesCovid()
        ? [
            {
              name: "vaccinationStatusIdCovid",
              label: "Covid Vaccination Status",
              options: {
                display: "excluded",
                filter: true,
                filterType: "dropdown",
                filterList: filter.vaccinationStatusIdCovid
                  ? [
                      enumVaccinationStatusFilterable[
                        filter.vaccinationStatusIdCovid
                      ],
                    ]
                  : [],
                filterOptions: {
                  names: filterObsoleteEnumsFromArray(
                    enumToArray(enumVaccinationStatusFilterable),
                    enumsVaccinationStatusObsolete,
                  ).map((s) => s?.name),
                },
                customFilterListOptions: {
                  render: (v) => `Covid Vaccination Status: ${v}`,
                },
              },
            },
          ]
        : []),
      {
        name: "name",
        label: "Name",
        options: {
          display: true, // by default
          render: (_v, i) => (i ? `${i.firstName} ${i.lastName}` : ""),
        },
      },
      {
        name: "dateOfBirth",
        label: "Date of Birth",
        options: {
          display: true, // by default
          searchable: true,
          render: (v) => moment(v, "YYYY-MM-DD").format("DD/MM/YYYY"),
        },
      },
      {
        name: "emailAddress",
        label: "Email Address",
        options: {
          display: true, // by default
        },
      },
      {
        name: "employeeNumber",
        label: "Employee Number",
      },
      {
        name: "nhsNumber",
        label: "NHS Number",
      },
      {
        name: "staffGroupName",
        label: "Staff Group",
        options: {
          customBodyRenderLite: (i) => {
            const item = items?.[i];
            if (!item) return;
            return item?.isEsr
              ? item?.staffGroupName
              : item?.nonEsrStaffTypeName;
          },
        },
      },
      {
        name: "jobRoleName",
        label: "Job Role",
      },
      {
        name: "nonEsrStaffTypeName",
        label: "Non ESR Staff Group",
      },
      {
        name: "level1",
        label: "Level 1",
      },
      {
        name: "level2",
        label: "Level 2",
      },
      {
        name: "level3",
        label: "Level 3",
      },
      {
        name: "level4",
        label: "Level 4",
      },
      {
        name: "postCode",
        label: "Postcode",
      },
      {
        name: "assignmentStatusName",
        label: "Active / Inactive",
        options: {
          display: true,
          filter: true,
          filterType: "dropdown",
          filterOptions: {
            names: enumToArray(
              filterHeterogenous(enumAssignmentStatusesFilterable),
            ).map((s) => s?.name),
          },
          // Selected option
          filterList: (() => {
            const optionValues = {
              // true, false, undefined, inversions etc
              IsActiveAssignment: filter?.IsInactiveAssignment === false,
              IsInactiveAssignment: filter?.IsInactiveAssignment === true,
              IsNotOnLatestEsr: filter?.IsNotOnLatestEsr,
              ShowArchived: filter?.isArchived,
            };
            // get optionValues that are true, i.e. actually selected and their human names from the enum
            return Object.keys(optionValues)
              .filter((k) => optionValues[k] === true)
              .map(
                (id) =>
                  enumKeyByValue(enumAssignmentStatusesFilterable, id) || id,
              );
          })(),
        },
      },
      {
        name: "mobileNumber",
        label: "Mobile Number",
      },
      {
        name: "role",
        label: "Role",
        options: {
          // 2.7 visually replaced 'Role' column with Flags, but still need to retain it for dropdown filtering
          display: false,
          filter: true,
          // Dropdown options
          filterOptions: {
            names: [
              "Vaccinator",
              "Clinic Admin",
              "Trust Admin",
              "Staff",
              "Reporting Access",
              // ^ If client insists role should appear as "Reporting Access", change it here as well as around line 790 and then uncomment the .replace block below. I didn't do this naturally because it's a bit backward (the options, pill render, etc are all based on the enum title)
              // UPDATE: He did.
            ],
          },
          // Current filter tags pill suffixes
          filterList: (() => {
            return filter?.securityRole
              ? [
                  startCase(SecurityRoleEnum[filter?.securityRole]).replace(
                    "Reporting Viewer",
                    "Reporting Access",
                  ),
                ]
              : filter?.[enumRolesFilterableExtra["Trust Admin"]]
                ? ["Trust Admin"]
                : [];
          })(),
          // Current filter tags pill prefix
          customFilterListOptions: {
            render: (v) => `Role: ${v}`,
          },
          // Text in table cell
          render: (role) => roleMap[role] ?? "",
        },
      },
      {
        name: "isFrontLine",
        label: (
          <div className="help-tooltip-wrapper">
            <span>Frontline</span>
            <Help helpCopyKey="1.4" />
          </div>
        ),
        options: {
          display: true,
          filter: true,
          filterType: "dropdown",
          filterOptions: {
            names: enumToArray(filterHeterogenous(enumFrontLineFilterable)).map(
              (s) => s?.name,
            ),
          },
          // Current selected option (from optional isFrontLine boolean)
          filterList: (() => {
            return filter?.isFrontLine !== undefined
              ? [
                  filter?.isFrontLine
                    ? enumKeyByValue(enumFrontLineFilterable, "isFrontLine")
                    : enumKeyByValue(enumFrontLineFilterable, "isNotFrontLine"),
                ]
              : [];
          })(),
          render: (v, i) => {
            return (
              <Switch
                // disable events with CSS instead, for styling demands #4617
                className={readOnly ? "toggle-readonly" : ""}
                checked={!!v}
                onChange={() => onFrontLineChange(i.id, !v)}
                value="active"
                color="primary"
                inputProps={{ "aria-label": "Is Frontline worker?" }}
              />
            );
          },
        },
      },
      {
        name: "isImmform",
        label: (
          <div className="help-tooltip-wrapper">
            <span>Immform</span>
            <Help helpCopyKey="1.3" />
          </div>
        ),
        options: {
          render: (v, i) => {
            return (
              <Switch
                // disable events with CSS instead, for styling demands #4617
                className={readOnly ? "toggle-readonly" : ""}
                checked={!!v}
                onChange={() => onImmformChange(i.id, !v)}
                value="active"
                color="primary"
                inputProps={{ "aria-label": "Is Immform?" }}
              />
            );
          },
        },
      },
      {
        name: "firstName",
        label: "First Name",
      },
      {
        name: "lastName",
        label: "Last Name",
      },
      {
        name: "isActive",
        label: "Is Active",
        options: {
          render: (v) => (v === false ? "Inactive" : "Active"),
        },
      },
      {
        name: "ethnicityName",
        label: "Ethnicity",
        options: {
          render: (v) => v ?? "Not Stated",
        },
      },
      {
        name: "genderName",
        label: "Gender",
      },
      {
        name: "atRisk",
        label: "At Risk",
        options: {
          render: (v) => v && "At Risk",
        },
      },
      {
        name: "atRiskDetails",
        label: "At Risk Reason",
      },
      ...pathogens
        .map((p) =>
          pathogenColumns.map((pc) => {
            // Ridiculous requests on the evening of launch
            // https://techdepartment.mydonedone.com/issuetracker/projects/85036/issues/13
            let label = `${Pathogens[p]} ${pc.label}`;
            if (label === "Covid Status") label = "Covid Booster Status";
            return {
              ...pc,
              name: `${pc.name}${Pathogens[p]}`,
              label,
              options: {
                // Another launch evening request, to show Flu Status and Covid Booster Status columns by default
                display: ["Status"].includes(pc.label),
                render: (v) => (
                  <span className="theme-color" data-theme-id={p}>
                    {pc.options?.render ? pc.options.render(v) : v}
                  </span>
                ),
              },
            };
          }),
        )
        .reduce((p1, p2) => p1.concat(p2), []),
      {
        name: "defrostDateCovid",
        label: "Covid Defrost Date",
        options: {
          render: (v) => (
            <span className="theme-color" data-theme-id="2">
              {humanDate(v)}
            </span>
          ),
        },
      },
      {
        name: "flags",
        label: "Flags",
        options: {
          display: true, // by default
          // customBodyRenderLite is more performant when sorting isn't necessary
          customBodyRenderLite: (i) => {
            const item = items?.[i];
            if (!item) return;
            return FlagIcons(item);
          },
          // Set column with to 100px
          setCellProps: () => ({ style: { minWidth: "75px" } }),

          filter: true,
          filterType: "dropdown",
          filterList: (() => {
            // current chosen option is an array of names (enumFlagsFilterable, "isAtRisk"));
            const filtersThatAreTrue = Object.keys(filter).filter(
              (k) => filter[k] === true,
            );
            if (filter.isVerified === false) {
              filtersThatAreTrue.push("isNotVerified");
            }
            if (filter.isEsr === false) {
              filtersThatAreTrue.push("isNotEsr");
            }
            if (matchesSelfRegisteredAndApproved(filter)) {
              filtersThatAreTrue.push("selfRegisteredAndApproved");
            }
            if (matchesSelfRegisteredAndUnapproved(filter)) {
              filtersThatAreTrue.push("selfRegisteredAndUnapproved");
            }
            const relevantFiltersThatAreTrue = filtersThatAreTrue?.filter((f) =>
              enumToArray(filterHeterogenous(enumFlagsFilterable))
                .map((s) => s?.id)
                .includes(f),
            );
            const relevantFiltersThatAreTrueHuman =
              relevantFiltersThatAreTrue.map(
                (id) => enumKeyByValue(enumFlagsFilterable, id) || id,
              );
            return relevantFiltersThatAreTrueHuman;
          })(),
          filterOptions: {
            // These get sorted alphabetically it seems, but the whole render can be overrided if needed https://github.com/gregnb/mui-datatables/blob/master/examples/customize-filter/index.js#L66
            // NB: If we're requested to change these to a multiselect/checkbox situation, it might be worth BB changing the IsFrontLine IsNewStarter style parameters on GET / trustworker to a flags array and rewriting this slightly but not strictly necessary
            names: enumToArray(filterHeterogenous(enumFlagsFilterable)).map(
              (s) => s?.name,
            ),
          },
          customFilterListOptions: {
            // Flag pill tag not appearing? Make sure it's it has an if statement in filterList() above
            render: (v) => `Flag: ${v}`,
          },
        },
      },
      ...(!readOnly
        ? [
            {
              name: "",
              options: {
                display: true,
                viewColumns: false,
                print: false,
                setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
                render: (_v, i) => renderRowActions({ item: i }),
              },
            },
          ]
        : []),
    ];
    if (trustInvolvesCovid()) {
      // #166 Inject 'First/Second Covid Vaccination Date' before the set of covid pathogen columns (if this trust has those)
      const pathogenColumnsCovidIndex = columnsProcessing.findIndex(
        (pc) => pc.name === "bookingDateCovid",
      );
      if (pathogenColumnsCovidIndex) {
        columnsProcessing.splice(pathogenColumnsCovidIndex, 0, {
          name: "secondCovidVaccinationDate",
          label: "Second Covid Vaccination Date",
          options: {
            render: (v) => humanDate(v),
          },
        });
        columnsProcessing.splice(pathogenColumnsCovidIndex, 0, {
          name: "firstCovidVaccinationDate",
          label: "First Covid Vaccination Date",
          options: {
            render: (v) => humanDate(v),
          },
        });
      }
    }
    setColumns(
      columnsProcessing.map((col) => ({
        ...col,
        options: {
          display: columnState?.[col.name] === true,
          customBodyRenderLite: col.options?.render
            ? (i) => {
                const item = items?.[i] || "";
                const value = item?.[col.name] || "";
                return col.options.render(value, item);
              }
            : undefined,
          filter: false,
          ...col.options,
        },
      })),
    );
  }, [items, pathogens]);

  //
  // OPTIONS
  //
  useEffect(() => {
    // Pagination reset for 0 returned items
    if (pathogens && items.length === 0 && options?.count !== 0) {
      setOptions({
        ...options,
        count: 0,
      });
      return;
    }
    if (!items?.length || !pathogens) {
      return;
    }
    setOptions({
      elevation: 0,
      serverSide: true,
      count: totalCount,
      rowsPerPage: filter.pageSize,
      rowsPerPageOptions: [25, 50, 100],
      page: filter.pageNumber - 1,
      sort: false, // disable alphabetic sorting of columns
      sortFilterList: false, // disable alphabetic sorting of filter drop options
      setFilterChipProps: setFilterChipProps(
        Pathogens,
        complianceFiltersEnabled,
      ),
      // Looking for filter dialog styles?
      // overrides.js -> overridesTableFilters
      // overrides.js -> overridesTableFiltersStaff
      //  (but there's also textLabels further below)
      print: false,
      searchPlaceholder: "Search",
      download: false, // see TableToolbar
      customToolbar: () => (
        <TableToolbar
          {...{
            setActiveModal: setExternalActiveModal,
            buttonsToShow,
            filter,
          }}
        />
      ),
      customToolbarSelect: (selectedRows) => (
        <TableToolbarSelect
          selectedRows={selectedRows}
          items={items}
          onRowsArchive={onRowsArchive}
          onRowsRestore={onRowsRestore}
          onRowsHardDelete={onRowsHardDelete}
          onRowsApprove={onRowsApprove}
          onRowsReject={onRowsReject}
          onRowsHardDeleteSR={onRowsHardDeleteSR}
        />
      ),
      // onRowsDelete: // handled by customToolbarSelect,
      onChangePage: (pageNumber) =>
        setFilter({ ...filter, pageNumber: pageNumber + 1 }),
      onChangeRowsPerPage: (numberOfRows) =>
        setFilter({ ...filter, pageSize: numberOfRows }),
      onFilterChange: (
        changedColumn,
        filterList,
        _type,
        changedColumnIndex,
        _displayData,
      ) => {
        const val = filterList[changedColumnIndex]?.[0];
        // console.log(":: ~ onFilterChange:", changedColumn, val);
        const _filter = filter;
        if (changedColumn === "role") {
          // Filter role is either undefined if no val, or the matching enum, or vaccinator if some other thing
          // PS: I cannot wait to rebuild this honking nonsense in P3
          // Update: that didn't happen, so it's rather esoteric at this point xD
          if (val === "Trust Admin") {
            // If expanding for other doodads, we'd loop over this enum like flags but for now, KISS. In future, update all occurrences here of enumRolesFilterableExtra
            _filter[enumRolesFilterableExtra["Trust Admin"]] = true;
            delete filter.securityRole;
          } else {
            delete _filter[enumRolesFilterableExtra["Trust Admin"]];
            delete filter.securityRole;
            if (val) {
              const securityRolesList = {
                Staff: SecurityRoleEnum.Staff,
                "Clinic Admin": SecurityRoleEnum.ClinicAdmin,
                "Reporting Access": SecurityRoleEnum.ReportingViewer,
              };
              filter.securityRole =
                securityRolesList[val] || SecurityRoleEnum.Vaccinator;
            }
          }
          setFilter({
            ..._filter,
            pageNumber: 1,
          });
        } else if (changedColumn === "assignmentStatusName") {
          enumToArray(enumAssignmentStatusesFilterable)
            .map((s) => s?.id)
            .forEach((id) => {
              delete _filter[id];
            });
          // IsInactiveAssignment isn't actually a thing, but just inversion of IsActiveAssignment
          if (val === "Active Assignments") {
            _filter.IsInactiveAssignment = false;
            _filter.IsNotOnLatestEsr = false;
          } else if (val) {
            _filter[enumAssignmentStatusesFilterable[val]] = true;
          }
          // removing the archived filter from pill
          if (_filter.includeArchived == true && _filter.isArchived == true) {
            delete _filter.includeArchived;
            delete _filter.isArchived;
            _filter.includeUnapproved = true;
          }
          // adding the archived filter from d/down
          if (_filter.ShowArchived) {
            delete _filter.ShowArchived;
            delete _filter.includeUnapproved;
            _filter.includeArchived = true;
            _filter.isArchived = true;
          }
          setFilter({
            ..._filter,
            pageNumber: 1,
          });
        } else if (changedColumn === "vaccinationStatusIdFlu") {
          // Cast to string otherwise 0 (no booking) doesn't display as a chip, for some stupid reason
          _filter.vaccinationStatusIdFlu =
            enumVaccinationStatus[val]?.toString();
          setFilter({
            ..._filter,
            pageNumber: 1,
          });
        } else if (changedColumn === "vaccinationStatusIdCovid") {
          _filter.vaccinationStatusIdCovid =
            enumVaccinationStatus[val]?.toString();
          setFilter({
            ..._filter,
            pageNumber: 1,
          });
        } else if (changedColumn === "flags") {
          // Default all flag filters to undefined
          enumToArray(enumFlagsFilterable)
            .map((s) => s?.id)
            .forEach((id) => {
              delete _filter[id];
            });
          if (val) {
            // Jam the flag enum into the _filter, as it might be right but correct each as we go:
            _filter[enumFlagsFilterable[val]] = true;
          }
          if (_filter.isVerified) {
            _filter.isVerified = true;
          } else if (_filter.isNotVerified) {
            _filter.isVerified = false;
            delete _filter.isNotVerified;
          }
          // Non ESR Staff
          if (_filter.isNotEsr) {
            _filter.isEsr = false;
          } else {
            delete _filter.isEsr;
          }
          delete _filter.isNotEsr;
          // removing the selfRegisteredAndApproved filter from pill
          if (matchesSelfRegisteredAndApproved(_filter)) {
            deleteSelfRegFilters(_filter);
            _filter.includeUnapproved = true;
          }
          // adding the selfRegisteredAndApproved filter from d/down
          if (_filter.selfRegisteredAndApproved) {
            setFilterToIncludeSelfRegAndApproved(_filter);
            delete _filter.selfRegisteredAndApproved;
          }
          // removing the selfRegisteredAndUnapproved filter from pill
          if (matchesSelfRegisteredAndUnapproved(_filter)) {
            deleteSelfRegFilters(_filter);
            _filter.includeUnapproved = true;
          }
          // adding the selfRegisteredAndUnapproved filter from d/down
          if (_filter.selfRegisteredAndUnapproved) {
            setFilterToIncludeSelfRegAndUnapproved(_filter);
            delete _filter.selfRegisteredAndUnapproved;
          }
          setFilter({
            ..._filter,
            pageNumber: 1,
          });
        } else if (changedColumn === "isFrontLine") {
          if (val) {
            _filter.isFrontLine = val === "Frontline";
          } else {
            delete _filter.isFrontLine;
          }
          setFilter({
            ..._filter,
            pageNumber: 1,
          });
        } else if (changedColumn === null) {
          // Reset
          // This default is also partially set in useListData.ts ~L#18
          setFilter({
            pageNumber: 1,
            includeUnapproved: true,
          });
        }
        // console.log("::afterFilterChange", _filter);
      },
      onSearchChange: (searchText) =>
        setFilter({ ...filter, pageNumber: 1, searchText }),
      onViewColumnsChange: (columnName, action) => {
        // MUIDataTable already handles column visibility,
        // so mutate state here to avoid unneccesary and slow rerender
        columnState[columnName] = action === "add";
      },
      searchAlwaysOpen: true,
      // customSearchRender: debounceSearchRender(500),
      customSearchRender: (searchText, handleSearch, hideSearch, options) => (
        <CustomDebouncedSearch
          {...{
            searchText,
            handleSearch,
            hideSearch,
            options,
          }}
        />
      ),
      textLabels: {
        body: {
          noMatch:
            // This concept doesn't work well because during load, there may well be data knocking about, so regardless of isLoading this noMatch thing doesn't trigger correctly but there's now a nice dumb little .table-loading workaround
            isLoading
              ? // <CircularProgress />
                ""
              : "Sorry, no matching records found",
        },
        filter: {
          all: "All",
          title: <h1>Filters</h1>,
          reset: (
            <Button
              onClick={(e) => {
                return;
              }}
              color="secondary"
              variant="contained"
              size="small"
              startIcon={<RotateLeft />}
            >
              Reset
            </Button>
          ),
        },
        selectedRows: {
          text: "record(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Records",
        },
      },
      setRowProps: (_row, dataIndex) =>
        !items?.[dataIndex]?.isActive && {
          style: {
            background: palette.inactive.main,
            color: palette.inactive.contrastText,
          },
        },
      // Override options, such as how compliance tab instance hides search
      ...optionsOverrides,
    });
  }, [setOptions, items]);

  const setFilterByAlertBox = (type) => {
    if (!["unapproved", "emailInDoubt"].includes(type)) {
      throw new Error("Invalid alert box type: " + type);
    }
    if (type === "unapproved") {
      setFilter({
        isApproved: false,
        includeUnapproved: true,
        pageNumber: 1,
        pageSize: filter.pageSize,
      });
    }
    if (type === "emailInDoubt") {
      setFilter({
        includeUnapproved: true,
        isEmailInDoubt: true,
        pageNumber: 1,
        pageSize: filter.pageSize,
      });
    }
  };
  //
  // console.log("::render", { pathogens, columns, options, items });
  // if (
  //   isLoading ||
  //   (items.length > 0 && (!pathogens?.length || !columns || !options))
  // ) {
  // ^ Nooo, that broke the search input!! #4728
  // This is an interstitial loading during the first paint, and there's another isLoading spinner below the table
  if (!pathogens?.length || !columns || !options) {
    return (
      <div style={{ display: "flex", placeContent: "center" }}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.tableOverrides}>
        {complianceFiltersEnabled && (
          <ComplianceFilters
            {...{
              pathogenIds: complianceFiltersPathogenIds,
              filter,
              setFilter,
              isLoading,
            }}
          />
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          {header && <h1>{header}</h1>}
          <div className="alertBoxCountContainer">
            {showAlertBoxes && alertBoxCounts.countEmailInDoubt > 0 && (
              <div
                severity="error"
                width="auto"
                variant="outlined"
                className="alertBoxCountItem"
              >
                <p>
                  <span className="errorIcon">
                    <Error />
                  </span>
                  {alertBoxCounts.countEmailInDoubt} to check
                </p>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={() => setFilterByAlertBox("emailInDoubt")}
                >
                  Email in Doubt
                </Button>
              </div>
            )}
            {showAlertBoxes && alertBoxCounts.countUnapproved > 0 && (
              <div
                severity="error"
                width="auto"
                variant="outlined"
                className="alertBoxCountItem"
              >
                <p>
                  <span className="errorIcon">
                    <Error />
                  </span>
                  {alertBoxCounts.countUnapproved} to approve
                </p>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={() => setFilterByAlertBox("unapproved")}
                >
                  Self Registrations
                </Button>
              </div>
            )}
          </div>
        </Box>
        <MUIDataTable
          data={items}
          columns={columns}
          options={options}
          elevation={0}
          className={`table-hack-actions table-horizontal-squeeze table-hack-th-tooltips table-fonts table-filter-pill-alignment ${
            isLoading ? "table-loading" : ""
          }`}
          // components={{ TableBody: BodyComponent }}
        />
        {isLoading && (
          <div style={{ display: "flex", placeContent: "center" }}>
            <CircularProgress />
          </div>
        )}
      </div>
    </ThemeProvider>
  );
}

function matchesSelfRegisteredAndApproved(_filter) {
  return (
    _filter.includeSelfRegistered == true &&
    _filter.isSelfRegistered == true &&
    _filter.includeUnapproved == false &&
    _filter.isApproved == true
  );
}

function setFilterToIncludeSelfRegAndApproved(filter) {
  filter.includeSelfRegistered = true;
  filter.isSelfRegistered = true;
  filter.includeUnapproved = false;
  filter.isApproved = true;
}

function matchesSelfRegisteredAndUnapproved(_filter) {
  return _filter.includeUnapproved == true && _filter.isApproved == false;
}

function setFilterToIncludeSelfRegAndUnapproved(filter) {
  filter.includeUnapproved = true;
  filter.isApproved = false;
}

function deleteSelfRegFilters(_filter) {
  delete _filter.isSelfRegistered;
  delete _filter.includeSelfRegistered;
  delete _filter.isApproved;
}
export default Table;
